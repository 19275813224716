import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';
import { AlertDialog, ConfirmDialog } from 'src/components/dialogs';
import PromptDialog from 'src/components/dialogs/PromptDialog';
import i18n from 'src/locales/i18n';

const MODAL_TYPES = {
  ALERT: 'ALERT',
  CONFIRM: 'CONFIRM',
  PROMPT: 'PROMPT',
};

const DIALOGS = {
  [MODAL_TYPES.ALERT]: AlertDialog,
  [MODAL_TYPES.CONFIRM]: ConfirmDialog,
  [MODAL_TYPES.PROMPT]: PromptDialog,
};

const DialogsContext = createContext();

const DialogsProvider = ({ children }) => {
  const [currentDialog, setCurrentDialog] = useState(null);

  const triggerDialog = ({ confirmLabel, discardLabel, message, title, type, ...others }) =>
    new Promise((resolve) => {
      setCurrentDialog({ confirmLabel, discardLabel, message, resolve, title, type, ...others });
    });

  const handleDialogResolve = (result) => {
    if (currentDialog && currentDialog.resolve) {
      currentDialog.resolve(result);
    }
    setCurrentDialog(null);
  };

  const alert = ({
    message = i18n.t('commons.alertGenericMessage'),
    title = i18n.t('commons.alertGenericTitle'),
    ...others
  } = {}) => triggerDialog({ message, title, type: MODAL_TYPES.ALERT, ...others });

  const confirm = ({
    message = i18n.t('commons.confirmGenericMessage'),
    title = i18n.t('commons.confirmGenericTitle'),
    discardLabel,
    confirmLabel,
  } = {}) =>
    triggerDialog({ confirmLabel, discardLabel, message, title, type: MODAL_TYPES.CONFIRM });

  const prompt = ({
    message = i18n.t('commons.confirmGenericMessage'),
    title = i18n.t('commons.confirmGenericTitle'),
    discardLabel,
    confirmLabel,
  } = {}) =>
    triggerDialog({ confirmLabel, discardLabel, message, title, type: MODAL_TYPES.PROMPT });

  const DialogComponent = currentDialog ? DIALOGS[currentDialog.type] : null;

  return (
    <DialogsContext.Provider value={{ alert, confirm, prompt }}>
      {children}
      {DialogComponent && (
        <DialogComponent
          {...currentDialog}
          confirmLabel={currentDialog.confirmLabel}
          dialogType={currentDialog.type}
          discardLabel={currentDialog.discardLabel}
          message={currentDialog.message}
          onConfirm={handleDialogResolve}
          onDiscard={handleDialogResolve}
          open
          title={currentDialog.title}
        />
      )}
    </DialogsContext.Provider>
  );
};

DialogsProvider.propTypes = {
  children: PropTypes.node,
};

export { DialogsContext, DialogsProvider, MODAL_TYPES };
