import { gql } from '@apollo/client';

import { companyFragment, financingFragment, invoiceFragment } from '../fragments';

const LIST_FINANCING_PROGRESSION = gql`
  query listFinancingProgression($companyId: ID!) {
    listFinancingProgression(companyId: $companyId) {
      total
      financing {
        id
        financingNumber
        reference
        totalAmountDue
        createdAt
        module
        isRefunded
        contractSignedAt
        financingDetails {
          refundedPercentage
        }
      }
    }
  }
`;

const LIST_FINANCING_INVOICES = gql`
  query listFinancingInvoices($companyId: ID) {
    listFinancingInvoices(companyId: $companyId) {
      ...Financing
      invoices {
        ...Invoice
      }
    }
  }
  ${invoiceFragment}
  ${financingFragment}
`;

const GET_FINANCING = gql`
  query getFinancing($financingId: ID!) {
    getFinancing(financingId: $financingId) {
      ...Financing
      invoices {
        ...Invoice
      }
      company {
        id
        faviconUrl
        businessName
        isInDefault
        metadata {
          mandateUrl
        }
        createdAt
      }
      contract {
        id
        displayName
      }
    }
  }
  ${invoiceFragment}
  ${financingFragment}
`;

const GET_HUBSPOT_DEALS = gql`
  query getDealsByCompanyId($companyId: ID!) {
    getDealsByCompanyId(companyId: $companyId) {
      dealName
      durationMonths
      hubspotDealId
      periodAmountPayback
      periodVariablePayback
      refundType
      totalAmountDue
      totalAmountPaidOut
    }
  }
`;

const GET_FINANCING_IDENTIFIERS = gql`
  query getFinancingIdentifiers($module: String!) {
    getFinancingIdentifiers(module: $module) {
      contractNumber
      reference
    }
  }
`;

const GET_REFUND_STATE = gql`
  query getRefundState($amortizationId: ID!) {
    getRefundState(amortizationId: $amortizationId) {
      amountDue
      periodVariablePayback
      refundType
      amortizationAmount
      expiration {
        amortizationId
        expiredAmount
        expiresAt
        financingId
        financingReference
      }
    }
  }
`;

const GET_OUTSTANDING_FINANCING = gql`
  query getOutstandingFinancing($companyId: ID!) {
    getOutstandingFinancing(companyId: $companyId) {
      date
      outstandingValue
    }
  }
`;

const GET_LAST_FINANCING_BY_COMPANY_ID = gql`
  query getLastFinancingByCompanyId($companyId: ID!, $financingNumber: Int!) {
    getLastFinancingByCompanyId(companyId: $companyId, financingNumber: $financingNumber) {
      reimbursementPercentage
      reference
      id
      totalAmountPaidOut
    }
  }
`;

const COUNT_FINANCING_BY_STATUS = gql`
  query countFinancingByStatus($companyId: ID, $filters: FinancingFiltersInput!) {
    countFinancingByStatus(companyId: $companyId, filters: $filters) {
      all
      refunded
      inProgress
      default
    }
  }
`;

const GET_FINANCING_KEY_PERFORMANCE_INDICATORS = gql`
  query getFinancingKeyPerformanceIndicators($filters: FinancingFiltersInput) {
    getFinancingKeyPerformanceIndicators(filters: $filters) {
      field
      type
      value
      currentMonthEvolutionPercentage
      dataType
    }
  }
`;

const GET_FINANCING_OVERVIEW = gql`
  query getFinancingOverview($filters: FinancingFiltersInput) {
    getFinancingOverview(filters: $filters) {
      total {
        total
        percentage
      }
      ongoing {
        total
        percentage
      }
      refunded {
        total
        percentage
      }
      defaults {
        total
        percentage
      }
    }
  }
`;

const GET_FINANCING_STATISTICS = gql`
  query getFinancingStatistics($companyId: ID, $financingId: ID) {
    getFinancingStatistics(companyId: $companyId, financingId: $financingId) {
      total
      unpaid
      processing
      refunded
      remaining
      totalLateInterests
    }
  }
`;

const GET_FINANCING_STATISTICS_ONLY_CAPITAL = gql`
  query getFinancingStatisticsOnlyCapital($companyId: ID, $financingId: ID) {
    getFinancingStatisticsOnlyCapital(companyId: $companyId, financingId: $financingId) {
      total
      unpaid
      processing
      refunded
      remaining
    }
  }
`;

const LIST_FINANCING_GROUPED_BY_COMPANY = gql`
  query listFinancingGroupedByCompany(
    $rowsPerPage: Int!
    $page: Int!
    $filters: FinancingFiltersInput
    $orderBy: OrderByInput
  ) {
    listFinancingGroupedByCompany(
      rowsPerPage: $rowsPerPage
      page: $page
      filters: $filters
      orderBy: $orderBy
    ) {
      hasMore
      items {
        company {
          company {
            ...Company
          }
          averageIrr
          averageReimbursementPercentage
          averageTotalAmountPaidOut
          financingNumber
          sumTotalAmountInterests
          sumTotalAmountPaidOut
        }
        financing {
          company {
            ...Company
          }
          id
          contractNumber
          contractSignedAt
          irr
          lastKnownIrr
          initialEstimatedIrr
          reference
          reimbursementPercentage
          status
          totalAmountInterests
          totalAmountPaidOut
        }
      }
      total
    }
  }
  ${companyFragment}
`;

const LIST_FINANCING = gql`
  query listFinancing(
    $companyId: ID
    $rowsPerPage: Int!
    $page: Int!
    $filters: FinancingFiltersInput
    $orderBy: OrderByInput
  ) {
    listFinancing(
      rowsPerPage: $rowsPerPage
      page: $page
      filters: $filters
      orderBy: $orderBy
      companyId: $companyId
    ) {
      hasMore
      items {
        company {
          ...Company
        }
        id
        contractNumber
        contractSignedAt
        irr
        reference
        reimbursementPercentage
        lastKnownIrr
        initialEstimatedIrr
        status
        hasStartedPayback
        totalAmountInterests
        totalAmountPaidOut
        isInDefault
        isUnderSurveillance
        mainProduct
      }
      total
    }
  }
  ${companyFragment}
`;

const LIST_TURNOVER_EVOLUTION = gql`
  query listTurnoverEvolution($financingId: ID!) {
    listTurnoverEvolution(financingId: $financingId) {
      turnover
      date
      status
    }
  }
`;

const GET_TOTAL_AMOUNT_FUNDED = gql`
  query getTotalAmountFunded {
    getTotalAmountFunded
  }
`;

const LIST_MARKETPLACE_FINANCING = gql`
  query listMarketplaceFinancing($companyId: ID!, $rowsPerPage: Int!, $page: Int!) {
    listMarketplaceFinancing(companyId: $companyId, rowsPerPage: $rowsPerPage, page: $page) {
      items {
        company {
          ...Company
        }
        id
        reference
        totalAmountPaidOut
        contractNumber
        durationMonths
        totalAmountInterests
        paidOutAt
        metadata {
          reserveRate
          requestedCashOutRate
        }
        financingDetails {
          refundedPercentage
          hasStartedPayback
        }
        createdAt
        lastDirectDebitDate
        module
        taeg
        updatedAt
        nominalInterestRate
        lateInterestRate
        firstDirectDebitDate
        isRefunded
        contractSignedAt
      }
      hasMore
      total
    }
  }
  ${companyFragment}
`;

export {
  GET_HUBSPOT_DEALS,
  COUNT_FINANCING_BY_STATUS,
  LIST_MARKETPLACE_FINANCING,
  LIST_TURNOVER_EVOLUTION,
  LIST_FINANCING_GROUPED_BY_COMPANY,
  GET_FINANCING,
  GET_FINANCING_KEY_PERFORMANCE_INDICATORS,
  GET_FINANCING_STATISTICS,
  LIST_FINANCING_INVOICES,
  GET_FINANCING_OVERVIEW,
  GET_LAST_FINANCING_BY_COMPANY_ID,
  GET_FINANCING_IDENTIFIERS,
  LIST_FINANCING_PROGRESSION,
  LIST_FINANCING,
  GET_OUTSTANDING_FINANCING,
  GET_REFUND_STATE,
  GET_TOTAL_AMOUNT_FUNDED,
  GET_FINANCING_STATISTICS_ONLY_CAPITAL,
};
